import cn from 'classnames'

interface OwnProps {
  show: boolean
  children: React.ReactNode
}

export default function TableDrawer({ show, children }: OwnProps) {
  if (!show) return null
  return (
    <tr className={cn('tw-animation-none')}>
      <td colSpan={'100%' as any} style={{ padding: '0 0' }}>
        <div
          className={cn(
            'tw-w-full',
            'tw-border-b',
            'tw-border-[#ececec]',
            'tw-bg-[#fafafa]',
          )}
        >
          {children}
        </div>
      </td>
    </tr>
  )
}

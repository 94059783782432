'use client'

import React, { useState } from 'react'
import styles from './NavBottomSection.module.scss'
import Link from 'next/link'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import AvatarCircle from '@crystal-eyes/components/elements/Avatar/AvatarCircle'
import ActivityToken from './ActivityToken'
import LedgerDrawer from './LedgerDrawer'
import MiniUtilityNav from './MiniUtlityNav'

export default function NavBottomSection({ expanded }: { expanded: boolean }) {
  const [isUtlityNavOpen, setIsUtilityNavOpen] = useState(false)
  const { data: me } = useMeV2()
  const [ledgerOpen, setLedgerOpen] = useState<boolean>(false)

  const isAdmin = me?.isOrgAdmin
  const personalSettingsPath = '/account/settings'

  const toggleCreditLedgerDrawer = () => {
    if (!isAdmin) return
    setLedgerOpen(!ledgerOpen)
  }

  return (
    <div
      className={`${styles.bottomContainer} ${expanded ? styles.expanded : ''}`}
    >
      {me?.identity && (
        <div className={styles.profile}>
          <AvatarCircle
            name={me?.identity?.personalInfo?.fullName}
            discType={me?.identity?.snapshot?.personality?.discType as string}
            photoUrl={me?.identity?.personalInfo?.photoUrl}
            r={17}
          />
          <Link
            href={personalSettingsPath}
            className={`CE title white medium ${styles.profileLink}`}
          >
            {me.identity.personalInfo?.fullName}
          </Link>
        </div>
      )}
      <div>
        <>
          {isAdmin && (
            <ActivityToken
              onClick={toggleCreditLedgerDrawer}
              expanded={expanded}
            />
          )}
          {ledgerOpen && (
            <LedgerDrawer
              drawerOpen={ledgerOpen}
              toggleDrawer={toggleCreditLedgerDrawer}
            />
          )}
        </>
      </div>

      <div className={styles.moreOptionsLink}>
        <MiniUtilityNav
          open={isUtlityNavOpen}
          setOpen={setIsUtilityNavOpen}
          expanded={expanded}
        />
        <span
          className={styles.hamburgerIconWrapper}
          onClick={() => setIsUtilityNavOpen(!isUtlityNavOpen)}
        >
          <Icon icon={Icons.Hamburger} className={styles.hamburgerIcon} />
        </span>
        <button onClick={() => setIsUtilityNavOpen(!isUtlityNavOpen)}>
          More options
        </button>
      </div>
      <div>
        <Link href="/logout" className={styles.logoutLink} prefetch={false}>
          <Icon icon={Icons.Download} className={styles.downloadIcon} />
          Logout
        </Link>
      </div>
    </div>
  )
}

import { FC, PropsWithChildren } from 'react'
import Chip from '@crystal-eyes/components/elements/Chip'
import styles from './SearchWrapper.module.css'
import classNamesBind from 'classnames/bind'
const cn = classNamesBind.bind(styles)

export interface Chip {
  key: string
  name: string
  handleClose: () => void
}

const SearchWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className={cn('searchWrapper')}>{children}</div>
    </>
  )
}

export default SearchWrapper

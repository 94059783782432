import { MouseEvent } from 'react'
import styles from './Chip.module.scss'

export type ChipDetails = {
  name: string
  label?: string
  error?: boolean
  handleClose?: (e: MouseEvent<HTMLButtonElement>) => void
}

export interface ChipProps {
  name: string
  label?: string
  error?: boolean
  success?: boolean
  handleClose?: (e: MouseEvent<HTMLButtonElement>) => void
}

const Chip = ({
  name,
  label,
  error = false,
  success = false,
  handleClose,
}: ChipProps) => {
  return (
    <span
      key={name}
      className={`${styles.chip} ${error ? styles.error : ''} ${
        success ? styles.success : ''
      }`}
    >
      {label ? <span className={styles.label}>{label + ':'} </span> : null}
      {name}
      {handleClose ? (
        <button
          data-name={name}
          className={`${styles.delete} ${error ? styles.error : ''}`}
          onClick={handleClose}
        />
      ) : null}
    </span>
  )
}

export default Chip

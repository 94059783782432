import { FC, PropsWithChildren, useMemo } from 'react'
import DefaultEmptyState from './EmptyState'
import { v4 as uuidv4 } from 'uuid'

import Row from './Row'

interface OwnProps {
  center?: boolean
  className?: string
  columns: any
  data: any[]
  Drawer?: React.ElementType | null
  loading?: boolean
  toggleAddProfilesModal: () => void
  onClick?: () => void
  EmptyState?: React.ElementType | null
}

const Rows: FC<PropsWithChildren<OwnProps>> = ({
  columns,
  data,
  Drawer,
  loading,
  toggleAddProfilesModal,
  onClick,
  EmptyState,
}) => {
  const showEmptyState = useMemo(
    () => !data || data.length === 0 || loading,
    [data, loading],
  )

  return (
    <>
      <tbody>
        {showEmptyState && (
          <DefaultEmptyState
            loading={loading || !data}
            length={columns.length}
            toggleAddProfilesModal={toggleAddProfilesModal}
            CustomEmptyState={EmptyState}
          />
        )}
        {!loading &&
          data?.map(item => (
            <Row
              key={item.id || item._uuid || (item._uuid = uuidv4())}
              data={item}
              columns={columns}
              Drawer={Drawer}
              onClick={onClick}
            />
          ))}
      </tbody>
    </>
  )
}

export default Rows

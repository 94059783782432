'use client'

import Drawer from '@crystal-eyes/premeeting/components/elements/Drawer'
import styles from './LedgerDrawer.module.css'
import Ledger from '@crystal-eyes/premeeting/components/Ledger'

interface OwnProps {
  toggleDrawer: () => void
  drawerOpen: boolean
}

export default function LedgerDrawer({ toggleDrawer, drawerOpen }: OwnProps) {
  return (
    <Drawer
      className={styles.ledgerDrawer}
      isOpen={drawerOpen}
      close={toggleDrawer}
    >
      <Ledger />
    </Drawer>
  )
}

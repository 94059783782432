import Chip from '@crystal-eyes/components/elements/Chip'
import cn from 'classnames'

export interface Chip {
  key: string
  name: string
  handleClose: () => void
}

function QueryChips({ chips }: { chips: Chip[] }) {
  return (
    <>
      <div className={cn('tw-pb-2.5', 'tw-text-center', 'tw-min-h-[34px]')}>
        {chips.map((chip: any) => (
          <Chip
            key={chip.key}
            name={chip.name}
            handleClose={chip.handleClose}
            success
          />
        ))}
      </div>
    </>
  )
}

export default QueryChips

import useSWR from 'swr'
import logger from '@crystal-eyes/utils/logger'

interface Identity {
  id: string
}

type Options = { identity?: Identity }

type TaggingOption = 'none' | 'tagging-profile'

type State = {
  taggingState: {
    state: TaggingOption
    setState: (newValue: TaggingOption) => void
  }
}

let sharedTagging: TaggingOption = 'none'
export default function useTagging({ identity: _ }: Options): State {
  const { data: taggingState, mutate: setTaggingState } = useSWR(
    'useTagging.taggingState',
    () => {
      if (sharedTagging !== 'none') logger.info('Tagging', sharedTagging)
      return sharedTagging
    },
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  )

  return {
    taggingState: {
      state: taggingState || 'none',
      setState: (newValue: TaggingOption) => {
        sharedTagging = newValue
        setTaggingState(newValue)
      },
    },
  }
}

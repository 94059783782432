'use client'

import React, { useEffect, useState } from 'react'
import Logo from '@crystal-eyes/premeeting/components/Logo'
import MiniLogo from '@crystal-eyes/premeeting/components/MiniLogo'
import styles from './NavLogoSection.module.css'
import Link from 'next/link'

export default function NavLogoSection({ expanded }: { expanded: boolean }) {
  const homePath = '/home'
  const [renderLogos, setRenderLogos] = useState<boolean>(false)
  useEffect(() => {
    setRenderLogos(true)
  }, [])

  const shouldRenderCrystalLogo = renderLogos
  return (
    <>
      {!renderLogos && <div className="tw-w-[74px]"></div>}

      {shouldRenderCrystalLogo && (
        <div
          className={`${styles.navHeader} ${expanded ? styles.expanded : ''}`}
        >
          <Link href={homePath}>
            {expanded ? (
              <div className="tw-text-white">
                <Logo />
              </div>
            ) : (
              <div className={`${styles.miniLogo}`}>
                <MiniLogo />
              </div>
            )}
          </Link>
        </div>
      )}
    </>
  )
}

import useSWR from 'swr'
import { createContext, useContext } from 'react'
import useAuth from '@crystal-eyes/hooks/useAuth'
import { Snapshot } from '@crystal-eyes/types'
import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'

export enum State {
  None = 'None',
  Loading = 'Loading',
  Error = 'Error',
  Loaded = 'Loaded',
}

export type CreditTransaction = {
  actionCode: string
  balance: number
  credit: number
  debit: number
  id: number
  createdAt: string
  profileCreated: Snapshot
  userProfile: Snapshot
}

export type PaginatedCreditTransactions = {
  creditTransactions: CreditTransaction[]
  metadata: {
    totalCount: number
    more: boolean
  }
}

export type CreditTransactionsState = {
  data:
    | {
        paginatedCreditTransactions: PaginatedCreditTransactions
      }
    | undefined
  refetch: () => void
  loading?: boolean
  error?: any
}

export type Options = {
  page?: number
  pageSize?: number
}

const CREDIT_TRANSACTIONS_QUERY = gql`
  query AdminCreditTransactions($page: Int, $pageSize: Int) {
    paginatedCreditTransactions(page: $page, pageSize: $pageSize) {
      creditTransactions {
        actionCode
        balance
        credit
        debit
        id
        createdAt
        profileCreated {
          source
          id
          identity {
            id
            personalInfo {
              fullName
              photoUrl
              firstName
              lastName
            }
          }
        }
        userProfile {
          id
          identity {
            id
            personalInfo {
              fullName
              photoUrl
              firstName
              lastName
            }
          }
          personality {
            id
            discType
            discCircumplex {
              degrees
              intensity
            }
          }
        }
      }
      metadata {
        totalCount
        more
      }
    }
  }
`

export const useCreditTransactionsContext = createContext<
  CreditTransactionsState | undefined
>(undefined)

export default function useCreditTransactions({
  page,
  pageSize,
}: Options): CreditTransactionsState {
  const { data: auth } = useAuth()
  const existingCtx = useContext(useCreditTransactionsContext)

  const fetcher = ([_key, page, pageSize]: [string, number, number]) =>
    queryWithAuth(auth, CREDIT_TRANSACTIONS_QUERY, {
      page: page || 1,
      pageSize: pageSize || 10,
    })

  const {
    data,
    isLoading: loading,
    mutate: refetch,
    error,
  } = useSWR(auth ? ['useCreditTransactions', page, pageSize] : null, fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
  })

  if (existingCtx) return existingCtx
  return {
    data,
    loading,
    error,
    refetch,
  }
}

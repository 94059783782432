import { Api } from '@premeeting/lib/api'
import { DEV_MODE } from './constants'
import { noticeError } from '@crystal-eyes/utils/instrumentation'
export {
  pathnameHelper,
  rsEvent,
  rsPage,
  rsIdentify,
  rsGroup,
} from './analytics/helpers'

const HUBSPOT_PORTAL_ID = '1716276'

const HSSubmitFormData = (
  portalID: string,
  formGUUID: string,
  email: string,
  fields: Array<{ name: string; value: any }>,
  options?: { context: { pageUri: string; pageName: string } },
) => {
  if (!email || DEV_MODE) return

  fields.push({ name: 'email', value: email })

  return Api()
    .post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${formGUUID}`,
      {
        fields,
        skipValidation: true,
        ...options,
      },
    )
    .catch(e => {
      noticeError('HSSubmitFormData Error', e)
    })
}

const CREATE_FREE_USER_HUBSPOT_FORM_ID = '54ccc630-5ee2-4c72-ac0b-5a99110be16d'
export function newUserRegistered(
  email: string,
  firstName?: string,
  lastName?: string,
  company?: string | null,
  partner?: string | null,
) {
  const params = [
    {
      name: 'firstname',
      value: firstName,
    },
    {
      name: 'lastname',
      value: lastName,
    },
    {
      name: 'company',
      value: company,
    },
    {
      name: 'partner',
      value: partner,
    },
  ].filter(param => param.value)

  HSSubmitFormData(
    HUBSPOT_PORTAL_ID,
    CREATE_FREE_USER_HUBSPOT_FORM_ID,
    email,
    params,
    {
      context: {
        pageUri: 'https://www.crystalknows.com/app/register',
        pageName: 'Get Started - Free Crystal Account',
      },
    },
  )
}

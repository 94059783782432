import SortArrow from './SortArrow'

function TableHeader({
  columns,
  sortCallback,
  getSortInfo,
}: {
  columns: any
  sortCallback?: any
  getSortInfo?: any
}) {
  return (
    <thead>
      <tr>
        {columns.map((column: any) => (
          <th
            key={column.key}
            style={column.customStyle ? column.customStyle : {}}
            onClick={() =>
              column.sortable && sortCallback && sortCallback(column.sortable)
            }
          >
            <div>
              {column.label}
              {column.sortable && (
                <SortArrow direction={getSortInfo(column.key).direction} />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader

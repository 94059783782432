'use client'

import { useState } from 'react'
import { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import { FixedColorIcons } from '@crystal-eyes/components/elements/Icon/FixedColorIcon'
import cn from 'classnames'
import Table from '@crystal-eyes/premeeting/components/elements/Table/Table'
import LoadingBar from '@crystal-eyes/components/elements/Loader/LoadingBar'
import LoadingBars from '@crystal-eyes/components/elements/Loader/LoadingBars'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import useCreditTransactions, {
  CreditTransaction,
} from '@crystal-eyes/hooks/admin/useCreditTransactions'
import { formatDateFilterString } from '@crystal-eyes/lib/date'
import Pagination from '@crystal-eyes/premeeting/components/Pagination'
import useOrganization from '@crystal-eyes/hooks/contextual/useOrganization'

export const PREDICTION_SOURCES = {
  API: {
    title: 'api',
    note: 'API lookup',
    icon: FixedColorIcons.Browser,
  },
  CALENDAR_INTEGRATION: {
    title: 'calendar integration',
    note: 'Lookup from calendar integration',
    icon: FixedColorIcons.Browser,
  },
  CHROME_GMAIL_WEB: {
    title: 'gmail',
    note: 'Gmail lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_HUBSPOT_WEB: {
    title: 'hubspot',
    note: 'Hubspot lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_LINKEDIN: {
    title: 'linkedin',
    note: 'LinkedIn lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_OUTLOOK_WEB: {
    title: 'outlook',
    note: 'Outlook lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_SALES_NAVIGATOR: {
    title: 'linkedin',
    note: 'LinkedIn Sales Navigator lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_SALESFORCE_WEB: {
    title: 'salesforce',
    note: 'Salesforce lookup',
    icon: FixedColorIcons.Browser,
  },
  CSV_ENRICHMENT: {
    title: 'csv',
    note: 'CSV enrichment',
    icon: FixedColorIcons.Browser,
  },
  DASHBOARD: {
    title: 'crystal dashboard',
    note: 'Crystal dashboard lookup',
    icon: FixedColorIcons.Browser,
  },
  OUTLOOK_VSTO: {
    title: 'outlook',
    note: 'Outlook lookup',
    icon: FixedColorIcons.Browser,
  },
  PARAGON_HUBSPOT: {
    title: 'hubspot',
    note: 'Hubspot lookup',
    icon: FixedColorIcons.Browser,
  },
  PARAGON_SALESFORCE: {
    title: 'salesforce',
    note: 'Salesforce lookup',
    icon: FixedColorIcons.Browser,
  },
  ZAPIER: {
    title: 'integration',
    note: 'Integration lookup',
    icon: FixedColorIcons.Browser,
  },
}

export const CREDIT_LEDGER_ACTIONS = {
  BILLING_CYCLE_REFRESH: {
    activity: 'Billing cycle refreshed',
    note: 'Billing cycle refreshed',
    icon: Icons.Refresh,
  },
  CRYSTAL_CORRECTION: {
    activity: 'Credit correction',
    note: 'Crystal made a correction to your account',
    icon: Icons.Refresh,
  },
  CRYSTAL_REWARD: {
    activity: 'Credit Gained',
    note: 'Received a Crystal reward',
    icon: Icons.Refresh,
  },
  CRYSTAL_REWARD_EXPIRED: {
    activity: 'Reward expiration',
    note: 'Crystal Reward expired',
    icon: Icons.Refresh,
  },
  FREE_PLAN_CREATION: {
    activity: 'Account creation',
    note: 'Free account created',
    icon: Icons.Refresh,
  },
  NEW_LICENSE_ADDED: {
    activity: 'License added',
    note: 'Added license to account',
    icon: Icons.Refresh,
  },
  PAID_PLAN_CREATION: {
    activity: 'Account creation',
    note: 'Business account created',
    icon: Icons.Refresh,
  },
  PLAN_CANCELLATION: {
    activity: 'Cancelled plan',
    note: 'Crystal plan cancelled',
    icon: Icons.Refresh,
  },
  PROFILE_CREATED: {
    activity: 'Profile created',
    note: 'Used a profile credit',
    icon: Icons.User,
  },
}

const itemsPerPage = 10

export default function Ledger() {
  const [page, setPage] = useState<number>(1)
  const { data: creditTransactionsData, loading: creditTransactionsLoading } =
    useCreditTransactions({ page: page, pageSize: 10 })
  const { data: me } = useMeV2()
  const { data: orgData } = useOrganization()
  const companyName =
    orgData?.org?.name || me?.identity?.personalInfo?.companyName

  const totalCount =
    creditTransactionsData?.paginatedCreditTransactions?.metadata?.totalCount
  const pageCount = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0

  const getNotesString = (transaction: CreditTransaction) => {
    const fullName =
      transaction?.profileCreated?.identity?.personalInfo?.fullName
    if (transaction?.profileCreated?.source) {
      return `${
        PREDICTION_SOURCES[
          transaction?.profileCreated?.source as keyof typeof PREDICTION_SOURCES
        ]?.note
      } ${fullName ? `for ${fullName}` : ''}`
    }
    if (fullName) return fullName
    return ''
  }

  const getDateWithTime = (date: string) => {
    const newDate = new Date(date)
    const day = formatDateFilterString(newDate)
    const time = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    }).format(newDate)
    return `${day} at ${time}`
  }

  const companyTitleString = `${
    companyName ? companyName : "My organization's"
  } activity`

  const tableColumns = [
    {
      key: 'activity',
      label: 'ACTIVITY',
      transform: (transaction: CreditTransaction) => {
        const activity = transaction.actionCode
          ? CREDIT_LEDGER_ACTIONS[
              transaction?.actionCode as keyof typeof CREDIT_LEDGER_ACTIONS
            ].activity
          : ''
        return <td>{activity}</td>
      },
    },
    {
      key: 'notes',
      label: 'NOTES',
      transform: (transaction: CreditTransaction) => {
        const note =
          transaction.actionCode === 'PROFILE_CREATED'
            ? getNotesString(transaction)
            : CREDIT_LEDGER_ACTIONS[
                transaction.actionCode as keyof typeof CREDIT_LEDGER_ACTIONS
              ].note
        return <td>{note}</td>
      },
    },
    {
      key: 'date',
      label: 'DATE + TIME',
      transform: (transaction: CreditTransaction) => {
        const dt = getDateWithTime(transaction.createdAt)
        return <td>{dt}</td>
      },
    },
  ]

  const TableSkeleton = () => {
    return (
      <div
        className={cn(
          'tw-flex',
          'tw-justify-center',
          'tw-flex-col',
          'tw-gap-1',
        )}
      >
        <LoadingBar
          width="100%"
          style={{ height: '48px', margin: '0.625rem 0' }}
        />
        <LoadingBar
          width="100%"
          style={{ height: '48px', margin: '0.625rem 0' }}
        />
        <LoadingBar
          width="100%"
          style={{ height: '48px', margin: '0.625rem 0' }}
        />
      </div>
    )
  }
  return (
    <div className={cn('tw-p-6')}>
      <div className={cn('tw-text-lg', 'tw-text-strong')}>
        {companyTitleString}
      </div>
      <LoadingBars loading={creditTransactionsLoading} count={7}>
        <Table theme="simple">
          <Table.Main>
            <Table.Header columns={tableColumns} />
            <Table.Rows
              data={
                creditTransactionsData?.paginatedCreditTransactions
                  ?.creditTransactions
              }
              columns={tableColumns}
              EmptyState={() => <TableSkeleton />}
            />
          </Table.Main>
        </Table>
      </LoadingBars>
      <div>
        <Pagination
          changePage={page => setPage(page)}
          currentPage={page}
          totalPages={pageCount}
        />
      </div>
    </div>
  )
}

import { debounce } from 'debounce'
import { useEffect, useRef } from 'react'

export function useDebounce<T extends (...args: any) => void>(
  callback: T,
  ms: number,
): T {
  const ref = useRef(debounce(callback, ms))

  useEffect(() => ref.current.clear, [])

  return ref.current
}

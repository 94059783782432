'use client'
import React, { useEffect, useState } from 'react'
import styles from './NavTopSection.module.scss'
import { useRouter, usePathname } from 'next/navigation'
import NavItem from './NavItem'
import type { NavSection, NavSectionTitle } from './types'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import Icon from '@crystal-eyes/components/elements/Icon/Icon'
import useIsFreeUser from '@crystal-eyes/hooks/user/useIsFreeUser'
import { getCookie } from '@crystal-eyes/lib/browser'
import { chrome_extension_installed_cookie } from '@premeeting/lib/utils/browser'

type Props = {
  section: NavSection
  activeSection: NavSectionTitle | null
  toggleActiveSection: (section: NavSectionTitle) => void
  expanded?: boolean
  external?: boolean
}

export default function NavTopSection({
  section,
  activeSection,
  toggleActiveSection,
  expanded,
  external,
}: Props) {
  const [hasCEInstalled, setHasCEInstalled] = useState<boolean | null>(null)
  const { data: me } = useMeV2()
  const { data: isFreeUser } = useIsFreeUser()
  const router = useRouter()
  const pathName = usePathname()
  const isAdmin = me?.isOrgAdmin

  useEffect(() => {
    setHasCEInstalled(!!getCookie(chrome_extension_installed_cookie))
  }, [])

  const headerClick = () => {
    if (section.path && external) {
      window && window.open(section.path, '_blank')
    } else if (section.path) {
      router.push(section.path)
    } else {
      toggleActiveSection(section.title)
    }
  }

  const isCalendarIntegrationDisabled = me?.features.includes(
    'disable_calendar_integration',
  )

  const hideProfileLibrary = me?.features.includes('disable_profile_library')
  if (
    section.title === 'Install Chrome Extension' &&
    (hasCEInstalled === null || hasCEInstalled)
  ) {
    return null
  }
  return (
    <div
      className={`${styles.navSection} ${expanded ? styles.expanded : ''}`}
      id={section.title}
    >
      <div
        className={`${styles.navTitle} ${
          section.path && pathName === section.path ? styles.selected : ''
        } ${section.path ? styles.noSubNav : ''} ${
          external ? styles.external : ''
        }`}
        onClick={headerClick}
      >
        <Icon icon={section.icon} />
        {section.title}
      </div>
      <div
        className={`${styles.navLinks} ${
          activeSection === section.title ? styles.show : ''
        }`}
      >
        {section.items.map(item => {
          if (item.title === 'CRMs' && !isAdmin) return null
          if (
            item.title === 'Calendar' &&
            (isCalendarIntegrationDisabled || isFreeUser)
          ) {
            return null
          }

          if (item.title === 'Profile Library' && hideProfileLibrary) {
            return null
          }
          return (
            <NavItem
              key={item.title}
              item={item}
              active={pathName ? pathName === item.path : false}
              expanded={expanded}
            />
          )
        })}
      </div>
    </div>
  )
}

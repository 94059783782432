'use client'
import React, { useEffect } from 'react'
import Script from 'next/script'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import { getPrimaryEmailFromList } from '@premeeting/lib/profile'

export default function Jimo() {
  const { data: me } = useMeV2()

  useEffect(() => {
    if (!window?.jimo || !me?.emails) return
    // add jimo user
    const { emails, identity } = me
    const primaryEmail = getPrimaryEmailFromList(emails)
    if (primaryEmail) window.jimo.push(['set', 'user:email', [primaryEmail]])
    if (identity?.personalInfo?.fullName)
      window.jimo.push(['set', 'user:name', [identity?.personalInfo?.fullName]])
    if (identity?.personalInfo?.companyName)
      window.jimo.push([
        'set',
        'user:attributes',
        [
          {
            organization: identity?.personalInfo?.companyName,
          },
        ],
      ])
    if (me?.createdAt)
      window.jimo.push([
        'set',
        'user:attributes',
        [
          {
            created_at: me?.createdAt,
          },
        ],
      ])
  }, [me])

  return (
    <Script
      id="jimo-widget"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        window.jimo = [];
        (function(j,i,m,o) {
          var s = document.createElement("script");

          s.type = "text/javascript";
          s.async = true;
          s.src = "https://undercity.usejimo.com/jimo-invader.js";
          j['JIMO_PROJECT_ID'] = "450877aa-791b-4705-8e56-c97b8d112600";
          document.getElementsByTagName("head")[0].appendChild(s);
        })(window);`,
      }}
    />
  )
}

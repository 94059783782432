import { useState, useEffect } from 'react'
import styles from './Search.module.scss'
import Image from 'next/image'
import searchIcon from '@crystal-eyes/resources/images/search.svg'
import filterIcon from '@crystal-eyes/resources/images/filter.svg'
import classNamesBind from 'classnames/bind'
import { useDebounce } from '@crystal-eyes/hooks/useDebounce'
const cn = classNamesBind.bind(styles)

function TableSearch({
  handleSearch,
  type,
  className,
}: {
  handleSearch: (searchQuery: string) => void
  type: 'SEARCH' | 'FILTER'
  className?: string
}) {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedHandleSearch = useDebounce(handleSearch, 500)

  useEffect(() => {
    debouncedHandleSearch(searchQuery)
  }, [searchQuery])

  const placeholder = type === 'SEARCH' ? 'Search' : 'Filter'
  const icon = type === 'SEARCH' ? searchIcon : filterIcon

  return (
    <div className={cn('searchArea', className)}>
      <input
        className={styles.searchInput}
        placeholder={placeholder}
        value={searchQuery}
        onChange={evt => setSearchQuery(evt.target.value)}
      />
      <Image
        src={icon as any as string}
        width={13}
        height={13}
        alt={placeholder}
      />
    </div>
  )
}

export default TableSearch

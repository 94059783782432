import classNamesBind from 'classnames/bind'
import css from './Main.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  children: React.ReactNode
}

export default function Main({ children }: OwnProps) {
  return <table className={classNames('tableContainer')}>{children}</table>
}

import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import { useEffect } from 'react'
import styles from './MiniUtilityNav.module.scss'

const SETTINGS_PATH = '/account/settings'
const SUPPORT_AND_FAQ_PATH = 'https://docs.crystalknows.com'
const PRIVACY_PATH = 'https://crystalknows.com/privacy'
const TERMS_PATH = 'https://crystalknows.com/tos'

const miniUtilityNavLinks = [
  {
    name: 'Settings',
    icon: Icons.Gear,
    link: SETTINGS_PATH,
  },
  {
    name: 'Support & FAQ',
    icon: Icons.Clipboard,
    link: SUPPORT_AND_FAQ_PATH,
    targetBlank: true,
  },
]

interface OwnProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  expanded: boolean
}

export default function MiniUtilityNav({ open, setOpen, expanded }: OwnProps) {
  useEffect(() => {
    if (!expanded) setOpen(false)
  }, [expanded, setOpen])

  if (!open || !expanded) return null

  return (
    <div className={`${styles.container} ${expanded ? styles.expanded : ''}`}>
      <div className={styles.navLinkList}>
        {miniUtilityNavLinks.map((navLink, i) => (
          <a
            href={navLink.link}
            className={styles.navLink}
            key={`${navLink.name}-${i}`}
            target={navLink.targetBlank ? '_blank' : undefined}
          >
            <Icon icon={navLink.icon} />
            <span>{navLink.name}</span>
          </a>
        ))}
        <div className={`${styles.navLink} ${styles.privacyAndTermsLink}`}>
          <Icon icon={Icons.MinimalLock} />
          <div>
            <a href={PRIVACY_PATH} target="_blank">
              Privacy
            </a>{' '}
            &{' '}
            <a href={TERMS_PATH} target="_blank">
              Terms
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { FC, PropsWithChildren } from 'react'
import classNamesBind from 'classnames/bind'
import css from './Table.module.scss'
const classNames = classNamesBind.bind(css)

interface TableProps {
  theme?: string
}

const Table: FC<PropsWithChildren<TableProps>> = ({
  children,
  theme = 'default',
}) => {
  return <div className={classNames('tableWrapper', theme)}>{children}</div>
}

interface TableSubcomponents {
  Header: React.ElementType
  Main: React.ElementType
  QueryChips: React.ElementType
  Rows: React.ElementType
  Search: React.ElementType
  SearchWrapper: React.ElementType
}

const TableWithSubComponents = Table as React.FC<
  PropsWithChildren<TableProps>
> &
  TableSubcomponents

export default TableWithSubComponents

import styles from './Pagination.module.css'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import classNamesBind from 'classnames/bind'

interface Props {
  changePage: (page: number) => void
  currentPage: number
  totalPages: number
}

const cn = classNamesBind.bind(styles)

export default function Pagination({
  changePage,
  currentPage,
  totalPages,
}: Props) {
  const pages = [...Array(5)]
    .map((_, i) => currentPage - 2 + i)
    .filter(page => page >= 1 && page <= totalPages)

  const prevDisabled = currentPage === 1
  const nextDisabled = currentPage === totalPages

  const renderPageLink = (num: number) => {
    return (
      <div
        key={num}
        className={`${styles.page} ${
          num === currentPage ? styles.current : ''
        }`}
        onClick={() => changePage(num)}
      >
        {num}
      </div>
    )
  }

  return (
    <div
      className={cn(
        'tw-flex',
        'tw-text-center',
        'tw-pt-3',
        'tw-justify-center',
        'tw-mx-auto',
      )}
    >
      <div
        className={cn('page', 'arrow', 'tw-transform-180', {
          disabled: prevDisabled,
        })}
        onClick={() => changePage(currentPage - 1)}
      >
        <Icon icon={Icons.Arrow} style={{ width: 18, color: '#1E749F' }} />
      </div>
      {currentPage > 3 && (
        <>
          {renderPageLink(1)}
          {currentPage !== 4 && (
            <div className={cn('page', 'tw-pointer-events-none')}>...</div>
          )}
        </>
      )}
      {pages.map(num => renderPageLink(num))}
      {totalPages > 3 && currentPage < totalPages - 3 && (
        <div className={cn('page', 'tw-pointer-events-none')}>...</div>
      )}
      {totalPages > 2 &&
        currentPage < totalPages - 2 &&
        renderPageLink(totalPages)}
      <div
        className={`${styles.page} ${styles.arrow} ${styles.next} ${
          nextDisabled ? styles.disabled : ''
        }`}
        onClick={() => changePage(currentPage + 1)}
      >
        <Icon icon={Icons.Arrow} style={{ width: 18, color: '#1E749F' }} />
      </div>
    </div>
  )
}

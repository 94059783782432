import styles from './SortArrow.module.css'
import classNamesBind from 'classnames/bind'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
const cn = classNamesBind.bind(styles)

function SortArrow({ direction }: { direction: 'ASC' | 'DESC' }) {
  return (
    <div className={cn('sortArrow', 'tw-transform-90', direction)}>
      <div className={cn('tw-w-[18px]', 'tw-h-[18px]')}>
        <Icon icon={Icons.Arrow} />
      </div>
    </div>
  )
}

export default SortArrow

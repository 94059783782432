import Header from './components/Header'
import Main from './components/Main'
import QueryChips from './components/QueryChips'
import Rows from './components/Rows'
import Table from './components/Table'
import Search from './components/Search'
import SearchWrapper from './components/SearchWrapper'

Table.Header = Header
Table.Main = Main
Table.QueryChips = QueryChips
Table.Rows = Rows
Table.Search = Search
Table.SearchWrapper = SearchWrapper

export default Table
